import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./get-started.scss";
import "../../styles/shared.scss";
import {
  getPage,
  TypeGetStartedPage,
  TypeGetStartedQueryResult,
} from "../../utils/queries/get_started";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import linkResolver from "../../utils/linkResolver";
import usePreviewData from "../../utils/usePreviewData";
import {
  ResourceInteruption,
  ResourceInteruptionGetStartedStatic,
} from "../../templates/resources/customers";
import { QuoteCarousel } from "../../components/reusable/quote-carousel";
import { TypeHomepageV2Quote } from "../../utils/queries/homepagev2";
import {
  CustomerStandardCard,
  StandardCard,
} from "../../templates/resources/cards";
import { Resource } from "../../utils/queries/resources";
import HubspotForm from "../../components/hubspot-form";
import { HomepageLogoCarousel } from "../../components/homepage-logos/carousel";

const query = graphql`
  {
    allPrismicGetStarted {
      edges {
        node {
          data {
            cta_button_text
            cta_text
            page_meta_description
            second_cta_button_text
            logos_section_header
            logos {
              logo {
                url
              }
            }
            how_it_works_large_img {
              url
              alt
            }
            section_title1
            section_title2
            title
            meta_title
            hero_image {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
            services {
              title1
              description
              image {
                alt
                url
              }
            }
            second_cta_button_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            cta_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            quotes {
              quote
              author_position
              author_name
              logo {
                alt
                url
              }
            }
            page_meta_thumbnail {
              url
              alt
            }
            customer_stories {
              case_study {
                document {
                  ... on PrismicResource {
                    id
                    data {
                      title
                      tags {
                        tag {
                          document {
                            ... on PrismicResourceIndustry {
                              id
                              data {
                                industry
                                display_in_filter_dropdown
                              }
                            }
                            ... on PrismicResourceTopic {
                              id
                              data {
                                topic
                                display_in_filter_dropdown
                              }
                            }
                          }
                        }
                      }
                      post_date
                      featured_on_customer_page
                      description {
                        html
                        raw
                        text
                      }
                      company_name
                      featured
                      link {
                        url
                        document {
                          ... on PrismicCaseStudy {
                            id
                            uid
                          }
                        }
                      }
                      image {
                        alt
                        url
                      }
                      resource_type {
                        document {
                          ... on PrismicResourceType {
                            id
                            data {
                              display_in_filter_dropdown
                              type
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GetStartedQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeGetStartedQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicGetStarted",
    true
  ) as TypeGetStartedPage;

  const renderableResources = result.customer_stories.map(
    (rawResource) => new Resource(rawResource.case_study.document.data)
  );
  const title = result.meta_title ? result.meta_title : result.title;
  return (
    <AmazeePage location={location}>
      <div id="get-started">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="how-it-works">
          <div className="container">
            <div className="row flex justify-center mb-4 lg:mb-0">
              <div className="col-12 col-lg-10 text-center">
                <h3>{RichTextRender(result.section_title1)}</h3>
              </div>
            </div>
            <div id="form-anchor"></div>
            <div
              id="how-it-works-large-img"
              className="row mb-6 mt-4 flex justify-center"
            >
              <div className="col-lg-9">
                <img
                  src={result.how_it_works_large_img.url}
                  alt={result.how_it_works_large_img.alt}
                />
              </div>
            </div>
            <div className="row">
              {result.services.map((service, index) => (
                <div className="col-12 col-lg-4 service" key={index}>
                  <img src={service.image.url} alt={service.image.alt} />
                  <div className="service-title">{service.title1}</div>
                  <div className="description">{service.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="form_section">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-8 text-center">
                <HubspotForm
                  key="2912"
                  formId="a83834ec-126b-47b8-a4c1-3cb460f78582"
                  portalId="5096486"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="quotes">
          <div className="container">
            <div className="row">
              <div className="col-12 quote-carousel-section">
                <div className="quotes-g2-badge flex justify-center">
                  <img
                    src="https://amazeeio.cdn.prismic.io/amazeeio/ff8921bd-ed04-4a60-9c64-44b4bbc1179e_G2-amazee-io-4-Badges-Winter-2023.svg"
                    title="G2 badges"
                  />
                </div>
                <QuoteCarousel
                  withComma={true}
                  quotes={result?.quotes?.map(
                    (quote) =>
                      ({
                        image: quote.logo,
                        text: quote.quote,
                        author_name: quote.author_name,
                        author_title: quote.author_position,
                      } as TypeHomepageV2Quote)
                  )}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="logos" className="logos no-padding white">
          <div className="section_header">{result.logos_section_header}</div>
          <div className="container">
            <div className="row">
              <div className="col">
                <HomepageLogoCarousel itemsForTablet={2} logos={result.logos} />
              </div>
            </div>
          </div>
        </section>

        <section id="customers">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h3>{RichTextRender(result.section_title2)}</h3>
              </div>
            </div>

            <div className="row">
              {renderableResources.map((resource, index) =>
                resource.companyName ? (
                  <CustomerStandardCard resource={resource} key={index} />
                ) : (
                  <StandardCard resource={resource} key={index} />
                )
              )}
            </div>
          </div>
        </section>

        <ResourceInteruptionGetStartedStatic topMargin={false} />
      </div>
    </AmazeePage>
  );
};

const StaticGetStartedQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <GetStartedQueryPage location={location} data={data} />}
    />
  );
};
export default StaticGetStartedQueryPage;
